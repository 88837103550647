var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SearchBarContainer } from "../shared-components/searchInput/search-input-styles";
import { SearchInput } from "../shared-components/searchInput/SearchInput";
import DocLibContext from "../../../application/Context/DocLibContext";
export var SearchBar = function () {
    var _a = useContext(DocLibContext), searchDocs = _a.searchDocs, searchInput = _a.searchInput, handleKeyDown = _a.handleKeyDown, handleSearchInput = _a.handleSearchInput;
    var t = useTranslation(["common"]).t;
    return (_jsx(React.Fragment, { children: _jsxs(SearchBarContainer, { children: [_jsx("label", __assign({ htmlFor: "searchinput", style: { display: "none" } }, { children: t("Search") })), _jsx(SearchInput, { placeholder: t("Search"), id: "searchinput", value: searchInput, type: "text", onChange: handleSearchInput, handleSearch: searchDocs, onKeyDown: handleKeyDown })] }) }));
};
