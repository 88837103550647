var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "../../shared-components/Ui/Row";
import { Text, ImageContainer, Title, Action, Container } from "../../Styles/SharedStyledComponents";
import { Tag } from "../../Styles/SharedStyledComponents";
import { motion } from "framer-motion";
import { sliceStringAfterMaxLength } from "../../../../utils/Utils";
import DocLibContext from "../../../../application/Context/DocLibContext";
export var List = function () {
    var _a, _b;
    var t = useTranslation(["news"]).t;
    var docs = useContext(DocLibContext).docs;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    var currentAuthStatus = ((_b = document.getElementById("auth-user")) === null || _b === void 0 ? void 0 : _b.value) || false;
    var isAuthenticated = currentAuthStatus === 'True';
    return (_jsx(React.Fragment, { children: _jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 }, exit: { opacity: 0 } }, { children: docs.map(function (doc, i) {
                var getFileUrl = function () {
                    if (langCode === 'fr') {
                        return doc.file_fr || doc.file_en;
                    }
                    else {
                        return doc.file_en || doc.file_fr;
                    }
                };
                var fileUrl = getFileUrl();
                var urlPrefix = langCode === "fr" ? "/fr/documents" : "/en/documents";
                var slug = langCode === "fr" ? doc.slug_fr : doc.slug_en;
                return (_jsx(Container, { children: _jsxs(Row, { children: [_jsx("div", __assign({ className: "col-md-5 ps-md-0 mb-3" }, { children: _jsx("a", __assign({ href: "".concat(urlPrefix, "/").concat(slug) }, { children: _jsx(ImageContainer, __assign({ width: 20, marginBottom: 14 }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/default-Image-company.png"), alt: "banner" }) })) })) })), _jsxs("div", __assign({ className: "col-md-7 mb-3" }, { children: [_jsx(Title, { children: _jsx("a", __assign({ href: "".concat(urlPrefix, "/").concat(slug) }, { children: langCode === "fr" ? doc.titre_doc_fr : doc.titre_doc_en })) }), _jsxs(Text, { children: [_jsx("p", { children: sliceStringAfterMaxLength(120, langCode === "fr" ? doc.description_doc_fr : doc.description_doc_en) }), _jsxs("div", __assign({ className: "flex-wrap" }, { children: [doc.categorie && doc.categorie.map(function (cat) { return _jsxs(Tag, { children: [" ", cat["nom_".concat(langCode)], " "] }, cat.id); }), doc.sector && doc.sector["title_".concat(langCode)] && _jsxs(Tag, { children: [" ", doc.sector["title_".concat(langCode)], " "] })] }))] }), isAuthenticated && (_jsx(Action, { children: _jsx("a", __assign({ href: "/".concat(langCode, "/pdfviewer/?file=").concat(fileUrl), target: "_blank", rel: "noreferrer" }, { children: t('Visionner le document') })) }))] }))] }) }, i));
            }) })) }));
};
